+(function($) {
	/***********************************
	* For all page
	*
	* ***********************************/
	// initialize WoW js plugin
	new WOW().init();

	// Preload - CSS based
	(function() {
		$(window).load(function() { 
			$("#preloader").delay(100).fadeOut("slow");
			$("#load").delay(100).fadeOut("slow");
		});
	})();


	// jQuery to collapse the navbar on scroll
	(function() {
		$(window).scroll(function() {
			if ($(".navbar").offset().top > 50) {
				$(".navbar-fixed-top").addClass("top-nav-collapse");
			} else {
				$(".navbar-fixed-top").removeClass("top-nav-collapse");
			}
		});
	})();

	$(function() {
		// jQuery for page scrolling feature - requires jQuery Easing plugin
		(function() {
			$('.navbar-nav li a').on('click', function(event) {
				var $anchor = $(this);
				$('html, body').stop().animate({
					scrollTop: $($anchor.attr('href')).offset().top
				}, 1500, 'easeInOutExpo');
				event.preventDefault();
			});
		})();
		(function() {
			$('.page-scroll a').on('click', function(event) {
				var $anchor = $(this);
				$('html, body').stop().animate({
					scrollTop: $($anchor.attr('href')).offset().top
				}, 1500, 'easeInOutExpo');
				event.preventDefault();
			});
		})();
		// change dropdown to hover
		(function() {
			$('.dropdown').hover(function() {
				$(this).toggleClass('open');
			});
		})();
		// contact form onsubmit
		(function() {
			$('#contact-form').submit(function(event) {
				$.post($("#contact-form").attr('action'), $("#contact-form").serialize()).done(function(data) {
					$('#contact-modal').modal('show');
					$('#contact-modal-title').html(data.modaltitle);
					$('.contact-modal-body').html(data.modalbody);
					if (data.state == 'ok') {
						$('#contact-form')[0].reset();
					}
				});
				event.preventDefault();
			});
		})();
	});

	/***********************************
	* Webshop Cart
	*
	* ***********************************/
	
	$(function() {
		// webshop cart
		$.get("/webshop/cart", function(data,status) {
			$('#cart').html(data.cart);
			$('#cartlist').html(data.cartlist);
		});
		// webshop button
		$.get("/webshop/button", function(data,status) {
			$('#item001_button').html(data.button1);
			$('#item002_button').html(data.button2);
			$('#item003_button').html(data.button3);
			$('#item004_button').html(data.button4);
			$('#item005_button').html(data.button5);
			$('#item006_button').html(data.button6);
			// add item function
			$('.button_add_to_cart').on('click', function() {
				var item = $(this).data("itemid");
				$.get("/webshop/add/" + item, function(data,status) {
					$('#cart').html(data.cart);
					$('#cartlist').html(data.cartlist);
					//flyToCart(item);
					console.log(data);

					list_data = '<div class="popup_cart_content col-xs-12">';
			        for (index = 0; index < data.cart_item_list.message.length; ++index) {
			        	//console.log(data.cart_item_list.message[index]);
			            list_data += '<div id="item2_'+data.cart_item_list.message[index]['id']+'" class="product_row row">'+
			            	'<div class="product_image">'+
		                        '<img src="'+data.cart_item_list.message[index]['image']+'" class="img-responsive center-block" alt="'+data.cart_item_list.message[index]['name']+'" title="'+data.cart_item_list.message[index]['name']+'">'+
			                '</div>'+
			                '<div class="text-left title">'+
			                	'<span class="product_name">'+data.cart_item_list.message[index]['name']+'</span>'+
			                    '<div class="product_price">'+
			                        '<span class="rowprice product_price">'+data.cart_item_list.message[index]['price']+'.- Ft/db</span>'+
			                    '</div>'+
			                '</div>'+
			                '<div class="margin_top center-block item_count">'+
			                    '<div class="button_spinner center-block" data-itemid="'+data.cart_item_list.message[index]['id']+'" >'+
			                        '<input type="text" class="touchspinner" data-unit="'+data.cart_item_list.message[index]['options']['unit']+'" id="qty2_'+data.cart_item_list.message[index]['id']+'" value="'+data.cart_item_list.message[index]['qty']+'">'+
			                    '</div>'+
			                '</div>'+
			            '</div>';
					}
					list_data += '</div>'+
									'<div class="row cart_popup_shipping">'+
										'<div class="sum col-sm-6 col-xs-12">'+
											'<h3 class="margin_top">Szállítási költség:</h3>'+
										'</div>'+
									'<div class="col-sm-6 col-xs-12 text-right">'+
										'<span class="cart_shipping" id="cart_shipping">'+data.shipping+'.- Ft</span>'+
									'</div>'+
								'</div>';
					list_data += '</div>'+
									'<div class="row cart_popup_price">'+
										'<div class="sum col-sm-6 col-xs-12">'+
											'<h3 class="margin_top">Szappanyok ára összesen:</h3>'+
										'</div>'+
									'<div class="col-sm-6 col-xs-12 text-right">'+
										'<span class="cart_total">'+data.total+'.- Ft</span>'+
									'</div>'+
								'</div>';
					list_data += '<div class="cart_popup_buttons">'+
									'<div class="row">'+
										'<div class="col-sm-6 col-xs-12">'+
											'<a href="#" title="Tovább vásárolok" class="popup_button_1 btn btn-info">Tovább vásárolok</a>'+
										'</div>'+
									'<div class="col-sm-6 col-xs-12">'+
										'<a href="/rendeles" title="Megrendelem" class="popup_button_2 btn btn-success pull-right">Megrendelem</a>'+
									'</div>'+
								'</div></div></div>';
					$('.cart_popup').html(list_data);

					$('.cart_popup_container').css('padding-top', (($('.cart_popup_wrap').height() - $('.cart_popup_container').height()) / 3)+'px');

			        $(".cart_popup .touchspinner").TouchSpin({
				        min: 0,
				        max: 20,
				        step: 1
				    });
					
			        $('body').addClass('cart_popup_active');
				});
			});
		});
	});

	// empty webshop cart
	(function() {
		$('#cartlist').on('click', '#button_empty_webshop_cart', function() {
			$.get("/webshop/empty", function(data,status) {
				$('#cart').html(data.cart);
				$('#cartlist').html(data.cartlist);
			});
		});
	})();

	// show cart list
	(function() {
		$("#cartholder").hover(function() {
			$('#cart')
				.toggleClass("animated bounceInRight viewable")
				.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
					$(this).removeClass("bounceInRight animated")
				});
			$('#cartlist')
				.toggleClass("animated bounceInRight viewable")
				.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
					$(this).removeClass("bounceInRight animated")
				});
		}, function() {
			$('#cart')
				.toggleClass("animated bounceOutRight")
				.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
					$(this).removeClass("bounceOutRight animated viewable")
				});
			$('#cartlist')
				.toggleClass("animated bounceOutRight")
				.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
					$(this).removeClass("bounceOutRight animated viewable")
				});
		});
	})();

	/***********************************
	* Webshop Page
	*
	* ***********************************/
	// check if 10 or more than 10 was bought
	var checkDiscount1 = function (qty) {
		if (qty >= 10) {
			$('#discount1_on').removeClass('hidden').addClass('show_icon');
			$('#discount1_off').removeClass('show_icon').addClass('hidden');
		} else {
			$('#discount1_on').removeClass('show_icon').addClass('hidden');
			$('#discount1_off').removeClass('hidden').addClass('show_icon');
		}
	};

	// check if 15 or more than 15 was bought
	var checkDiscount2 = function (qty) {
		if (qty >= 15) {
			$('#discount2_on').removeClass('hidden').addClass('show_icon');
			$('#discount2_off').removeClass('show_icon').addClass('hidden');
		} else {
			$('#discount2_on').removeClass('show_icon').addClass('hidden');
			$('#discount2_off').removeClass('hidden').addClass('show_icon');
		}
	};

	// list quantity modify
	(function() {
		$('#order').on('change', '.button_spinner', function() {
			var item = $(this).data("itemid"),
				qty = $(this).find('.spinner').val(),
				unit = $(this).find('.spinner').data('unit');
			$.get("/webshop/order/" + item + "/qty/" + qty, function(data,status) {
				$('#cart').html(data.cart);
				$('#cartlist').html(data.cartlist);
				var price = $("#" + item + " .price").html();
				$("#" + item + " .rowprice").html(parseInt(price) * qty);
				$("#sum_holder").html(data.listfoot);
				$('#delivery_price_2').html(data.deliveryprice2);
				$('#delivery_price_3').html(data.deliveryprice3);
				$('#delivery_price_4').html(data.deliveryprice4);
				$('#delivery_price_5').html(data.deliveryprice5);
				$('#check').html(data.check);
				$('.cart_popup .cart_total').html(data.total+'.- Ft');
				checkDiscount1(data.carttotalunits);
				checkDiscount2(data.carttotalunits);
			});
		});
	})();

	(function() {
		$('.cart_popup').on('change', '.button_spinner', function() {
			var item = $(this).data("itemid"),
				qty = $(this).find('.touchspinner').val(),
				unit = $(this).find('.touchspinner').data('unit');
			$.get("/webshop/order/" + item + "/qty/" + qty, function(data,status) {
				$('#cart').html(data.cart);
				$('#cartlist').html(data.cartlist);
				var price = $("#" + item + " .price").html();
				$("#" + item + " .rowprice").html(parseInt(price) * qty);
				$("#sum_holder").html(data.listfoot);
				$('#delivery_price_2').html(data.deliveryprice2);
				$('#delivery_price_3').html(data.deliveryprice3);
				$('#delivery_price_4').html(data.deliveryprice4);
				$('#delivery_price_5').html(data.deliveryprice5);
				$('#check').html(data.check);
				$('#qty_'+item).val(qty);
				$('.cart_popup .cart_total').html(data.total+'.- Ft');
				$('.cart_popup .cart_shipping').html(data.shipping+'.- Ft');
				checkDiscount1(data.carttotalunits);
				checkDiscount2(data.carttotalunits);
			});
		});
	})();

	// list delete button
	(function() {
		$(document).on('click', '.button_delete_from_order_list', function() {
			var item = $(this).data("itemid");
			$.get("/webshop/order/" + item + "/delete", function(data,status) {
				if (data.state == 'ok') {
					$('#cart').html(data.cart);
					$('#cartlist').html(data.cartlist);
					$('#add_holder').html(data.listadd);
					$("#sum_holder").html(data.listfoot);
					$('#' + item).remove();
					if ($.trim($("#termekek_lista_body").html()) === '') {
						$("#termekek_holder").html(data.listtable);
					}
					$('#delivery_price_2').html(data.deliveryprice2);
					$('#delivery_price_3').html(data.deliveryprice3);
					$('#delivery_price_4').html(data.deliveryprice4);
					$('#delivery_price_5').html(data.deliveryprice5);
					$('#check').html(data.check);
					checkDiscount1(data.carttotalunits);
					checkDiscount2(data.carttotalunits);
				}
			});
		});
	})();

	// add to list
	(function() {
		$(document).on('click', '.button_add_to_order_list', function() {
			var item = $(this).data("itemid");

			$.get("/webshop/order/" + item + "/add", function(data,status) {
				$('#cart').html(data.cart);
				$('.order_table_shipping').html(data.shipping);
				$('#cartlist').html(data.cartlist);
				$('#add_holder').html(data.listadd);
				if ($("#termekek_lista").length > 0) {
					$("#termekek_lista_body").append(data.listrow);
				} else {
					$("#termekek_holder").html(data.listtable);
				}
				$("#sum_holder").html(data.listfoot);
				$('#delivery_price_2').html(data.deliveryprice2);
				$('#delivery_price_3').html(data.deliveryprice3);
				$('#delivery_price_4').html(data.deliveryprice4);
				$('#delivery_price_5').html(data.deliveryprice5);
				$('#check').html(data.check);
				$(".spinner").TouchSpin({
					min: 1,
					max: 99,
					step: 1,
					verticalbuttons: true,
					verticalupclass: 'fa fa-plus',
					verticaldownclass: 'fa fa-minus'
				});
				checkDiscount1(data.carttotalunits);
				checkDiscount2(data.carttotalunits);

				/*list_data = '<div class="popup_cart_content">';
		        for (index = 0; index < data.cart_item_list.message.length; ++index) {
		        	console.log(data.cart_item_list.message[index]);
		            list_data += '<div id="item2_'+data.cart_item_list.message[index]['id']+'" class="row">'+
		            	'<div class="product_image col-xs-3">'+
	                        '<img src="'+data.cart_item_list.message[index]['image']+'" class="img-responsive center-block" alt="'+data.cart_item_list.message[index]['name']+'" title="'+data.cart_item_list.message[index]['name']+'">'+
		                '</div>'+
		                '<div class="text-left col-xs-5 title">'+
		                	'<span class="product_name">'+data.cart_item_list.message[index]['name']+'</span>'+
		                    '<div class="product_price">'+
		                        '<span class="rowprice product_price">'+data.cart_item_list.message[index]['price']+'.- Ft/db</span>'+
		                    '</div>'+
		                '</div>'+
		                '<div class="margin_top center-block item_count col-xs-4">'+
		                    '<div class="button_spinner center-block" data-itemid="'+data.cart_item_list.message[index]['id']+'" >'+
		                        '<input type="text" class="touchspinner" data-unit="'+data.cart_item_list.message[index]['options']['unit']+'" id="qty2_'+data.cart_item_list.message[index]['id']+'" value="'+data.cart_item_list.message[index]['qty']+'">'+
		                    '</div>'+
		                '</div>'+
		            '</div>';
				}
				list_data += '</div><div class="row cart_popup_price"><div class="sum col-xs-6"><h3 class="hidden-xs margin_top">Összesen:</h3></div><div class="col-xs-6 text-right"><span class="cart_total">'+data.total+'.- Ft</span></div></div>';
				list_data += '<div class="cart_popup_buttons"><div class="row"><div class="col-xs-6"><a href="#" title="Tovább nézelődök" class="popup_button_1">Tovább nézelődök</a></div><div class="col-xs-6"><a href="/rendeles" title="Megrendelem" class="popup_button_2">Megrendelem</a></div></div></div></div>';
				$('.cart_popup').html(list_data);

				$('.cart_popup_container').css('padding-top', (($('.cart_popup_wrap').height() - $('.cart_popup_container').height()) / 3)+'px');

		        $(".cart_popup .touchspinner").TouchSpin({
			        min: 0,
			        max: 20,
			        step: 1
			    });
				
		        $('body').addClass('cart_popup_active');*/
			});
			
		});
	})();

	$('.cart_popup_wrap').on('click', '.close_popup_button', function(){
		$('body').removeClass('cart_popup_active');
	});

	$('.cart_popup_wrap').on('click', '.popup_button_1', function(){
		$('body').removeClass('cart_popup_active');
	});

	// change delivery
	(function() {
		$("input[name='delivery_mode']").on('change', function() {
			var delivery = $("input[name='delivery_mode']:checked").val();
			$.get("/webshop/delivery/" + delivery, function(data,status) {
				if (data.state == 'ok') {
					$('#check').html(data.check);
				}
			});
		});
	})();

	// change payment
	(function() {
		$("input[name='payment_mode']").on('change', function() {
			var payment = $("input[name='payment_mode']:checked").val();
			$.get("/webshop/payment/" + payment, function(data,status) {
				if (data.state == 'ok') {
					$('#check').html(data.check);
				}
			});
		});
	})();

	// customer - email change
	(function() {
		$("input[name='customer_email']").on('input', function() {
			var email = $("input[name='customer_email']").val();
			$.post("/webshop/customer/email", $("#webshop_form").serialize()).done(function(data) {
				if (data.state == 'ok') {
					$('#check').html(data.check);
				}
			});
		});
	})();

	// customer - email change
	(function() {
		$("input[name='customer_phone']").on('input', function() {
			var phone = $("input[name='customer_phone']").val();
			$.post("/webshop/customer/phone", $("#webshop_form").serialize()).done(function(data) {
				if (data.state == 'ok') {
					$('#check').html(data.check);
				}
			});
		});
	})();

	// customer - sameaddress change
	(function() {
		$("input[name='same_address']").on('change', function() {
			var sameaddress = $("#same_address").data('state');
			if (sameaddress == "same") {
				$('#address2_textarea').prop('required', true);
				$("#same_address").data('state', 'different');
				$('#address2_holder').removeClass('hidden').addClass('show');
			} else {
				$('#address2_textarea').prop('required', false);
				$("#same_address").data('state', 'same');
				$('#address2_holder').removeClass('show').addClass('hidden');
			}
			$.get("/webshop/customer/sameaddress/" + sameaddress, function(data,status) {
				$('#check').html(data.check);
			});
		});
	})();


	// redirect to main page on modal close
	(function() {
		$('#webshop_modal').on('hide.bs.modal', function (e) {
			window.location.href = 'http://' + window.location.hostname;
		})
		$('#contact-modal').on('hide.bs.modal', function (e) {
			window.location.href = 'http://' + window.location.hostname;
		})
	})();

	// customer - address form change
	(function() {
		$('.address_input').on('input', function(event) {
			$.post("/webshop/customer/address", $("#webshop_form").serialize()).done(function(data) {
				if (data.state == 'ok') {
					$('#check').html(data.check);
				}
			});
		});
	})();

	// customer - address2 form change
	(function() {
		$('.address2_input').on('input', function(event) {
			$.post("/webshop/customer/address", $("#webshop_form").serialize()).done(function(data) {
				if (data.state == 'ok') {
					$('#check').html(data.check);
				}
			});
		});
	})();

	// submit form
	/*(function() {
		$('#webshop_form').on('submit', function(e) {
			$.post($("#webshop_form").attr('action'), $("#webshop_form").serialize()).done(function(data) {
				$('#webshop_modal').modal('show');
				$('#webshop-modal-title').html(data.modaltitle);
				$('.webshop-modal-body').html(data.modalbody);
				if (data.state == 'ok') {
					$('#webshop_form')[0].reset();
				}
			});
			e.preventDefault();
		});
	})();*/

	$(function(){
		// TouchSpin button
		$(".spinner").TouchSpin({
			min: 1,
			max: 99,
			step: 1,
			verticalbuttons: true,
			verticalupclass: 'fa fa-plus',
			verticaldownclass: 'fa fa-minus'
		});

		// check
		$.get("/webshop/check", function(data,status) {
			$('#check').html(data.check);
		});

	});
	// init carousel
		$('.carousel').carousel({
			interval: 5000
		});
		// set the highest size to all carousel items function
		var setCarouselHeight = function(id) {
			var slideHeight = [];
			$(id+' .item').each(function() {
				// add all slide heights to an array
				slideHeight.push($(this).height());
			});
			// find the tallest item
			max = Math.max.apply(null, slideHeight);
			// set the slide's height
			$(id+' .carousel-content').each(function() {
				$(this).css('height',max+'px');
			});
		};
		// set the highest size to all carousel items
		setCarouselHeight('#text_carousel');
	// On window resize
		$(window).resize(function() {
			// set the slide's height to auto to extend
			$('#text_carousel .carousel-content').each(function() {
				$(this).css('height','auto');
			});
			// set the highest size to all carousel items
			setCarouselHeight('#text_carousel');
		});
$('[data-toggle="popover"]').popover();
})(jQuery);